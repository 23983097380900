import React, { useState } from 'react'
import { useSWRConfig } from 'swr'
import {
  canShowcase,
  getShowcaseBadges,
} from '../../../lib/policies/showcasePolicy'
import useBadge from '../../../lib/useBadge'
import useUser from '../../../lib/useUser'
import PopoutButton from './PopoutButton'

const AddToShowcase = ({ id, type, visibility, badges }) => {
  const { mutate } = useSWRConfig()
  const { user } = useUser()
  const { addToShowcase } = useBadge()
  const canShowcaseItem = canShowcase({
    type,
    visibility,
  })
  const showcaseBadges = getShowcaseBadges({ badges, user }) || []

  const [added, setAdded] = useState(false)

  const addItemToShowcase = async (badgeSlug) => {
    try {
      const showcase = {
        id,
        type,
      }
      const resp = await addToShowcase(showcase, badgeSlug)
      setAdded(true)
      setTimeout(() => {
        setAdded(false)
      }, 2000)
      mutate(
        `/api/badges/${badgeSlug}.json`,
        (oldData) => ({
          badge: { ...oldData?.badge, ...resp?.badge },
        }),
        false
      )
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setAdded(false)
    }
  }

  if (canShowcaseItem && showcaseBadges.length > 0) {
    return showcaseBadges.map((badge) => (
      <PopoutButton
        key={badge?.id}
        onClick={(e) => {
          addItemToShowcase(badge?.slug || badge?.id)
        }}
        className="line-clamp-2"
      >
        {added
          ? `Added to ${badge?.name} featured`
          : `Add to ${badge?.name} featured`}
      </PopoutButton>
    ))
  }

  return null
}

export default AddToShowcase
